<template>
  <div>
    <div :class="{ 'disabled-content': !username }" class="content">
      <div v-if="loading || !userData" class="flex items-center justify-center h-full">
        <LoaderSpinner class="absolute top-[45vh]" />
      </div>

      <div v-if="!loading && userData && userData.allow_store">
        <div v-if="product">
          <header class="flex-col lg:absolute lg:top-[103px] lg:left-[155px] md:pl-[20px] xs:w-[100%]">
            <div class="flex justify-between w-[100%]">
              <div class="flex gap-2 justify-center items-center cursor-pointer" @click="goHome">
                <img class="lg:h-[24px]" src="@/assets/arrows/left.svg" />
                <img class="lg:h-[55.36px] lg:w-[47.16px]" src="@/assets/obblogos/square_games.svg" />
              </div>

              <section class="flex items-center justify-center lg:hidden align-center">
                <button class="text-[#9C93B3] body-medium" @click="toggleShowDetails">
                  <p v-if="!showDetails" class="flex gap-2">
                    Details
                    <img src="@/assets/arrows/down.svg" />
                  </p>
                  <div v-else class="flex gap-2">
                    <p>Close</p>
                    <img src="@/assets/arrows/up.svg" />
                  </div>
                </button>
              </section>
            </div>
            <!-- Dropdown -->
            <ul v-if="showDetails" class="sticky top-[150px] w-[100%] bg-[black] pt-[23px] lg:hidden">
              <li class="flex w-[100%]">
                <div class="flex justify-between items-end gap-[14px] w-[100%] pb-[8px]">
                  <div class="flex gap-[14px]">
                    <img
                      v-if="product.image"
                      :src="product.image"
                      alt="product img"
                      class="bg-[#322C3F] h-[56px] w-[118px] rounded" />
                    <img
                      v-else
                      alt="default img"
                      class="bg-[#322C3F] h-[56px] w-[118px] rounded"
                      src="@/assets/placeholder.png" />
                    <div class="flex-col justify-center items-center space-y-[4px]">
                      <UsernameChip
                        :isFocused="isFocused"
                        :label="username ? username : ''"
                        :rightIcon="true"
                        :size="'small'"
                        @click="openUsernameModal" />
                      <p class="body-medium">{{ product.name }}</p>
                    </div>
                  </div>
                  <p class="body-medium">${{ taxFree }}</p>
                </div>
              </li>
              <li class="divider flex gap-4 h-auto py-[12px] text-[#9C93B3] justify-between">
                <p>Sales Tax (6.5%)</p>

                <p>${{ tax }}</p>
              </li>
              <li class="flex gap-4 h-auto py-[12px] justify-between">
                <p>Total Due</p>
                <p v-if="product.sale_price">${{ product.sale_price }}</p>
                <p v-else>${{ product.price }}</p>
              </li>
            </ul>
          </header>

          <main class="flex lg:flex-row xs:flex-col h-[100vh]">
            <!-- LEFT SIDE -->
            <div
              id="left"
              class="flex-col lg:mt-[30px] xs:mt-[35px] justify-center align-center items-center gap-4 w-[51%] h-[1080px] lg:pl-[198px] md:pl-[10px] lg:pt-[175px]">
              <div class="flex-col items-center justify-center mb-2">
                <UsernameChip
                  :isFocused="isFocused"
                  :label="username ? username : ''"
                  :rightIcon="true"
                  :size="chipSize"
                  @click="openUsernameModal" />
              </div>

              <div class="grid heading-large mb-[40px]">
                <div>
                  <p>{{ product.name }}</p>
                </div>
                <p class="body-large text-[#9C93B3]">Pay</p>
                <div v-if="product.sale_price" class="flex gap-2">
                  <p class="line-through">
                    <small>$ {{ product.price }}</small>
                  </p>
                  <p>$ {{ product.sale_price }}</p>
                </div>
                <div v-else>
                  <p>$ {{ product.price }}</p>
                </div>
              </div>

              <div class="grid gap-[8px] h-auto lg:w-[477px] xs:w-[228px]">
                <div id="productList" class="flex gap-[8px] w-[100%] justify-between items-end">
                  <div class="flex gap-[14px] w-[100%]">
                    <img
                      v-if="product.image"
                      :src="product.image"
                      alt="product img"
                      class="bg-[#322C3F] h-[48px] w-[102px]  rounded" />
                    <img
                      v-else
                      alt="default img"
                      src="@/assets/placeholder.png"
                      class="bg-[#322C3F] h-[48px] w-[102px] rounded" />
                  </div>
                  <p class="body-medium">${{ taxFree }}</p>
                </div>

                <ul class="body-medium w-[258px] h-auto lg:mx-[46%]">
                  <li class="divider flex gap-4 h-auto py-[12px] text-[#9C93B3] justify-between">
                    <p>Sales Tax (6.5%)</p>

                    <p>${{ tax }}</p>
                  </li>
                  <li class="flex gap-4 h-auto py-[12px] justify-between">
                    <p>Total Due</p>
                    <p v-if="product.sale_price">${{ product.sale_price }}</p>
                    <p v-else>${{ product.price }}</p>
                  </li>
                </ul>
              </div>
            </div>

            <!--RIGHT SIDE-->
            <div
              id="right"
              class="flex-col justify-center align-center lg:pt-[103px] lg:w-[49%] bg-[#16131B] m-0 lg:h-[1110px] mb-0 pl-0">
              <!-- FORM DETAILS -->
              <form
                action=""
                class="flex-col m-auto divide-y divide-[#322c3f] min-h-[662px] max-w-[70%] flex-wrap overflow-auto overflow-x-hidden"
                @submit.prevent="onSubmit">
                <div class="stripe-elements">
                  <!-- MOBILE VIEW: TOP -->
                  <section
                    id="mobileItem"
                    class="lg:hidden flex-col justify-center gap-[14px] py-[24px] h-auto m-auto bg-[#16131B] divide-y divide-[#322c3f]">
                    <div class="flex-col justify-center">
                      <img
                        v-if="product.image"
                        :src="product.image"
                        alt="product img"
                        class="bg-[#322C3F] h-[48px] w-[102px] rounded m-auto" />
                      <img
                        v-else
                        alt="default img"
                        class="bg-[#322C3F] h-[48px] w-[102px] rounded m-auto"
                        src="@/assets/placeholder.png" />
                      <div class="flex-col justify-center items-center text-center pt-[4px] pb-[8px]">
                        <p class="body-medium">{{ product.name }}</p>
                      </div>
                    </div>
                    <div class="flex-col items-center justify-center align-center">
                      <div class="text-center grid heading-large justify-center gap-[8px]">
                        <p class="body-large text-[#9C93B3] mt-[14px]">Pay</p>
                        <div v-if="product.sale_price" class="flex gap-2 text-center">
                          <p class="line-through">$ {{ product.price }}</p>
                          <p class="text-center">$ {{ product.sale_price }}</p>
                        </div>
                        <div v-else class="text-center">
                          <p>$ {{ product.price }}</p>
                        </div>
                      </div>
                    </div>
                  </section>
                  <form @submit.prevent="handlePayment">
                    <!-- Display any validation errors here -->

                    <!-- <div v-if="duplicateItem && error === null" class="error">
                      <div class="error-close" @click="closeDupeNotification">
                        X
                      </div>
                      You already have this item
                    </div> -->

                    <!-- Stripe Elements for card input -->
                    <div class="form-group">
                      <div class="relative">
                        <label for="cardElement">Card Number</label>
                        <div id="cardElement" class="h-[43px] py-[10px] mt-[5px] w-[100%] px-[12px]"></div>
                        <img
                          class="absolute inset-y-0 right-0 h-[16px] mx-3 pointer-events-none translate-y-[255%]"
                          src="@/assets/banks.png" />
                      </div>

                      <!-- Next Row: Expiry&Cvc -->
                      <div class="flex w-[100%] gap-[10px] mt-[8px]">
                        <div class="w-[50%]">
                          <label for="expiryElement">Expiry</label>
                          <div id="expiryElement" class="mt-[5px] h-[43px] py-[10px] px-[12px] w-[228px]"></div>
                        </div>
                        <div class="w-[50%]">
                          <label for="cvcElement">CVC</label>
                          <div id="cvcElement" class="mt-[5px] h-[43px] py-[10px] px-[12px] w-[228px]"></div>
                        </div>
                      </div>

                      <!-- Next row:Country&Postal -->
                      <div class="flex w-[100%] gap-[10px] mt-[8px]">
                        <div class="w-[50%]">
                          <label>Country</label>
                          <div id="country" class="mt-[5px] relative">
                            <select ref="select" class="h-[43px] py-[10px] px-[12px] outline-none border-none">
                              <option
                                v-for="country in countriesList"
                                :key="country"
                                :disabled="country === '-----------------------'">
                                {{ country }}
                              </option>
                            </select>
                          </div>
                        </div>

                        <div class="w-[50%]">
                          <label>Postal code</label>
                          <div id="post-code" class="mt-[5px]">
                            <input
                              class="h-[43px] py-[10px] px-[12px] w-[228px] focus:outline-none text-[14px]"
                              maxlength="10"
                              placeholder="90210"
                              type="text" />
                          </div>
                        </div>
                      </div>
                    </div>

                    <div v-if="error" class="text-[#F5AF0B] body-small">
                      <p class="flex gap-2 align-center pt-[32px]">
                        <span class="w-[16px]"><img src="@/assets/icons/warning.svg" /></span>
                        {{ error }}
                      </p>
                    </div>

                    <button
                      :class="{
                        'payButton primary pointer-events-auto': (showErrorMsg || formFilled) && !disableSubmitButton,
                        'payButton primaryDisabled pointer-events-none': !showErrorMsg || disableSubmitButton,
                      }"
                      :disabled="disableSubmitButton"
                      class="flex gap-2 justify-center align-center button-large w-[100%] h-[48px] rounded py-[12px] px-[16px] my-[32px]"
                      type="submit">
                      <span v-if="!paymentLoading" class="flex justify-center gap-2">
                        Pay
                        <p v-if="product.sale_price">${{ product.sale_price }}</p>
                        <p v-else>${{ product.price }}</p>
                      </span>
                      <span v-if="paymentLoading" class="flex justify-center gap-2">Please wait...</span>
                    </button>
                  </form>
                </div>

                <!-- DISCLAIMER + BUTTON-->
                <div class="flex-col text-[#F5AF0B] w-[100%] h-[156px] body-small">
                  <div class="my-[32px]">
                    <div v-if="ethHasError">
                      {{ ethError }}
                    </div>

                    <div v-if="ethOrderCanceled">
                      <div style="margin-bottom: 20px" class="flex flex-col justify-center align-center">
                        <div class="text-center mt-1">Order canceled</div>
                      </div>
                    </div>

                    <div v-if="ethAllDone && !showPaymentSuccessfulModal">
                      <LoaderSpinner
                        class="flex justify-center align-items-center"
                        style="transform: scale(0.4); margin-top: -40px; margin-bottom: -20px" />
                      <div class="flex justify-center">Waiting for transaction confirmation...</div>
                    </div>

                    <div v-if="ethAllDone" style="margin-bottom: 20px" class="flex flex-col justify-center align-center">
                      <div class="text-center mt-1">Your purchase request has been submitted.</div>
                      <div class="text-center">The purchase will be completed once the transaction confirms. This can take up to 15 minutes.</div>
                      <div class="text-center mt-1">You can close this window.</div>
                    </div>

                    <button
                      v-if="product.eth_price && product.eth_price !== '0' && !ethAllDone"
                      :disabled="ethLoading || ethButtonDisabled || ethAllDone"
                      :class="{ primaryDisabled: ethLoading || ethButtonDisabled || ethAllDone }"
                      class="payButton bg-[#221e2a] button-large w-[100%] h-[48px] rounded py-[12px] px-[16px] text-[#474153]e"
                      type="secondary"
                      @click="ethPurchaseStart">
                      <span v-if="!ethLoading">
                        Purchase with ETH/Stablecoins
                      </span>
                      <span v-if="ethLoading">Please wait...</span>
                    </button>
                  </div>
                  <div id="placeholderDisclaimer" class="xs:pb-[32px]">
                    <p class="flex items-center gap-2 align-center">
                      <img src="@/assets/icons/warning.svg" width="16" alt="warning-icon" />
                      Disclaimer:
                    </p>
                    <p class="pt-[16px]">
                      Refund Policy: We are not able to give refunds directly through the OBB support. Keep in mind that
                      a successful refund request will roll back your account resulting in losing items you have received
                      with the purchase. Have a beautiful day!
                    </p>
                  </div>
                </div>
              </form>
            </div>
          </main>

          <!-- FOOTER -->
          <footer
            class="lg:flex justify-start items-center h-[22px] lg:w-[267px] lg:top-[800px] absolute body-medium xs:bottom-0 m-auto w-[100%] lg:ml-[198px] md:ml-[10px]">
            <ul class="lg:flex xs:grid gap-[16px] text-[#9C93B3]">
              <li class="flex gap-[16px] lg:mb-0 xs:mb-[8px]">
                <div>
                  <p class="flex">
                    Powered by&nbsp;
                    <img src="@/assets/stripecheckout/stripe.svg" />
                  </p>
                </div>
                <p class="visibleDivider">|</p>
              </li>
              <div class="flex gap-[16px] justify-between">
                <li>
                  <a
                    href="https://app.termly.io/document/terms-of-service/b7fd45f7-987b-4f42-9a92-7ac82fb8b85f#purchases"
                    target="_blank">
                    Terms
                  </a>
                </li>
                <li>
                  <a
                    href="https://app.termly.io/document/privacy-policy/de385d3a-38ed-42be-85b5-24abc4a5ae3e"
                    target="_blank">
                    Privacy
                  </a>
                </li>
              </div>
            </ul>
          </footer>
        </div>
        <div v-else>
          <p>Loading...</p>
        </div>
      </div>
    </div>
    <!--ERROR ON PAYMENT  -->
    <!-- <UsernameModal v-if="!username"/> -->
    <UsernameModal v-if="showModal" @username-saved="onUsernameSaved" @close="showModal = !showModal"/>
    <LoginModal :showModal="!checkCookies" />

    <PaymentSuccessModal
      v-if="showPaymentSuccessfulModal"
      :fullPrice="fullPrice"
      :product="selectedProduct"
      :salePrice="salePrice"
      :tax="tax"
      :taxFree="taxFree"
      :is-eth="ethAllDone"
      :giftUser="this.giftUser"
      @close="closePaymentSuccessModal" />
  </div>
</template>

<script>
import axios from "axios";
import envConfig from "../config";
import UsernameModal from "./UsernameModal.vue";
import LoginModal from "./LoginModal/LoginModal.vue";
import PaymentSuccessModal from "./PaymentSuccessModal.vue";
import UsernameChip from "./UsernameChip.vue";
import LoaderSpinner from "./UI/LoaderSpinner.vue";
import { loadStripe } from "@stripe/stripe-js";

import countries from "@/countries";

export default {
  components: {
    UsernameModal,
    LoginModal,
    PaymentSuccessModal,
    UsernameChip,
    LoaderSpinner,
  },
  props: {
  },
  data() {
    return {
      // chip
      chipLabel: "Chip label",
      chipSize: "large",
      leftIcon: false,
      rightIcon: false,
      isFocused: false,
      //
      showPaymentSuccessfulModal: false,
      product: null,
      selectedProduct: null,
      baseUrl: `${envConfig.baseUrl}`,
      cardNumber: "",
      expirationDate: "",
      cvc: "",
      clientSecret: null,
      error: null,
      stripe: null,
      cardElement: null,
      cvcElement: null,
      expiryElement: null,
      separatorInserted: false,
      selectedProductPrice: null,
      showModal: false,
      salePrice: null,
      fullPrice: null,
      tax: null,
      taxFree: null,
      loading: true,
      dupeItem: false,

      countriesList: countries,

      cardElementComplete: false,
      cvcElementComplete: false,
      expiryElementComplete: false,
      //FORM RELATED
      formFilled: false,
      showErrorMsg: false,
      disableSubmitButton: false,
      // MOBILE
      showDetails: false,

      ethLoading: false,
      ethPurchaseData: null,
      ethHasError: false,
      ethAllDone: false,
      ethError: null,
      ethButtonDisabled: false,

      userToken: null,
      userData: null,
      buyerId: null,
      giftUser: null,

      displayName: null,

      paymentLoading: false,

      ethTransactionHash: null,
      ethTransactionNetwork: null,
      ethOrderId: null,
      ethOrderCanceled: false,
    };
  },
  computed: {
    username() {
      return this.displayName ? this.displayName : "";
    },
    checkCookies() {
      return window.getCookie("obb_ck");
    },
    stripeElements() {
      return this.$stripe.elements();
    },
  },
  async created() {
    const productId = this.$route.params.id;

    try {
      const response = await axios.get(`${envConfig.baseUrl}/api/product/${productId}`);
      if (!response || !response.data.data.web_store) {
        this.goHome();
      }
      this.product = response.data.data;
      this.salePrice = this.product.sale_price;
      this.fullPrice = this.product.price;
      this.calculateTax();
      this.loading = false;
    } catch (error) {
      console.error("Error fetching product:", error);
    }

    if (this.$route.params.orderId) {

      let orderId = this.$route.params.orderId;

      if (orderId > 0) {
        this.ethOrderId = orderId;
        this.ethLoading = false;
        this.ethAllDone = true;
        this.ethButtonDisabled = true;


        this.checkEthOrderStatus();
      } else {

        this.ethLoading = false;
        this.ethAllDone = false;
        this.ethButtonDisabled = false;
        this.ethOrderCanceled = true;
      }
    }
  },
  async mounted() {
    this.userToken = window.getCookie("obb_ck");
    this.fetchUser();

    this.stripe = await loadStripe(`${envConfig.stripePubKey}`);

    // Test style for the Card Element
    const style = {
      base: {
        color: "#fff",
        fontFamily: "Arial, sans-serif",
        fontSmoothing: "antialiased",
        fontSize: "14px",
        "::placeholder": {
          color: "#564b6c",
        },
      },
      invalid: {
        color: "#fa755a",
        iconColor: "#fa755a",
      },
    };

    // Create a Card Element
    const elements = this.stripe.elements();
    this.cardElement = elements.create("cardNumber", { style });
    this.cvcElement = elements.create("cardCvc", { style });
    this.expiryElement = elements.create("cardExpiry", { style });

    // Wait for Card Element to appear
    while (document.querySelector("#cardElement") === null) {
      await new Promise((r) => setTimeout(r, 0));
    }

    // Mount the Card Element to the DOM
    this.cardElement.mount("#cardElement");
    this.cvcElement.mount("#cvcElement");
    this.expiryElement.mount("#expiryElement");

    this.selectedProductPrice = this.product.sale_price ? this.product.sale_price : this.product.price;

    this.cardElement.on("change", (event) => {
      this.cardElementComplete = event.complete;
      this.updateFormFilled();
    });
    this.cvcElement.on("change", (event) => {
      this.cvcElementComplete = event.complete;
      this.updateFormFilled();
    });
    this.expiryElement.on("change", (event) => {
      this.expiryElementComplete = event.complete;
      this.updateFormFilled();
    });

    if (this.$route.query.showUser) {
      this.openUsernameModal();
      this.$router.replace({'query': null})
    }
  },

  methods: {
    ethPurchaseStart() {
      if (!this.product || !this.username || this.ethLoading) {
        return;
      }

      this.ethOrderCanceled = false;
      this.ethHasError = false;
      this.ethError = null;
      this.ethAllDone = false;
      this.ethButtonDisabled = false;
      this.ethTransactionHash = null;

      this.ethLoading = true;

      let ref = this;

      axios
          .post(envConfig.baseUrl.concat("/api/crypto-purchase/init"), {
            product: this.product.id,
            username: this.username,
            buyer: this.buyerId,
          })
          .then((resp) => {
            if (!resp.data.error) {


              this.ethPurchaseData = resp.data;
              ref.ethOrderId = resp.data.order_id;

              window.location.href = resp.data.payment_link;

            } else {
              this.ethError = resp.data.error;
              this.ethHasError = true;
              this.ethLoading = false;
              this.ethButtonDisabled = true;
            }
          })
          .catch(() => {
            this.ethError = "There was an error preparing the transaction. Please try again or get in touch";
            this.ethHasError = true;
            this.ethLoading = false;
          });
    },

    checkEthOrderStatus() {
      if (this.ethOrderId) {
        axios.get(envConfig.baseUrl.concat("/api/eth-purchase/status/").concat(this.ethOrderId)).then((resp) => {
          if (resp.data.completed) {
            if (this.$route.params.id === "24") {
              window.location.href = `${envConfig.websiteUrl}/profile?p=${this.$route.params.id}`;
            } else {
              this.showPaymentSuccessModal();
            }
          } else {
            setTimeout(() => {
              this.checkEthOrderStatus();
            }, 10000);
          }
        });
      }
    },

    updateFormFilled() {
      if (this.cardElementComplete && this.cvcElementComplete && this.expiryElementComplete) {
        if (this.error !== null) {
          this.dupeItem = true;
        } else {
          this.formFilled = true;
        }
      } else {
        this.formFilled = false;
      }
    },
    onUsernameSaved() {
      this.showModal = false;
      this.displayName = localStorage.getItem('username');
      if (this.displayName !== this.userData.username) {
        this.giftUser = this.displayName;
      }
      // Reload page to fetchClientSecret
      //this.$router.go();
    },
    showPaymentSuccessModal() {
      this.showPaymentSuccessfulModal = true;
      this.selectedProduct = this.product;
    },
    closePaymentSuccessModal() {
      this.showPaymentSuccessfulModal = false;
      // Redirect the user to the first page of the store
      this.$router.push("/");
    },
    closeErrorNotification() {
      this.error = null;
    },
    async fetchClientSecret() {
      let data = await fetch(`${envConfig.baseUrl}/api/create-payment`, {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify({
          orderAmount: parseFloat(this.selectedProductPrice),
          userName: this.username,
          productId: this.product.id,
          buyerId: this.buyerId,
        }),
      })
        .then((response) => response.json())
        .then((data) => {
          return data;
        })
        .catch((error) => {
          console.error("Error fetching clientSecret:", error);
          this.error = "Failed to fetch payment information. Please try again later.";
        });

      if (data.error) {
        console.log("error:", data.error);
        console.log("product id:", this.product.id);
        this.error = data.error;

        return null;
      } else {
        this.clientSecret = data.client_secret;
        // console.log("Client secret:", this.clientSecret);
      }

      return data.client_secret;
    },
    handlePayment() {
      // Disable the form submit button in case user clicks it too many times/too fast
      this.disableSubmitButton = true;
      this.ethOrderCanceled = false;

      this.paymentLoading = true;

      this.fetchClientSecret().then((clientSecret) => {
        if (clientSecret) {
          this.clientSecret = clientSecret;

          this.stripe
            .confirmCardPayment(this.clientSecret, {
              payment_method: {
                card: this.cardElement,
              },
            })
            .then((result) => {
              if (result.error) {
                // Payment failed
                this.disableSubmitButton = true;
                console.error("Payment error:", result.error.message);
                this.error = result.error.message;
              } else {
                // Payment succeeded
                console.log("Payment succeeded:", result.paymentIntent);
                // Reset form fields
                this.cardNumber = "";
                this.expirationDate = "";
                this.cvc = "";
                // Clear any previous errors
                this.error = null;
                // Show the successful payment popup
                if (this.$route.params.id === "24") {
                  window.location.href = `${envConfig.websiteUrl}/profile?p=${this.$route.params.id}`;
                } else this.showPaymentSuccessModal();

                //Hide Payment declined message
                this.showErrorMsg = false;
              }

              this.paymentLoading = false;
            })
            .catch((error) => {
              console.error("Error processing payment:", error);
              this.error = "An error occurred while processing the payment. Please try again.";
            })
            .finally(() => {
              // Enable the form submit button
              this.disableSubmitButton = false;
              this.paymentLoading = false;
            });
        }
      });
    },

    // Mobile-Specific
    toggleShowDetails() {
      this.showDetails = !this.showDetails;
    },

    openErrorMsg() {
      this.showErrorMsg = true;
    },
    openUsernameModal() {
      this.showModal = true;
    },
    closeModal() {
      this.showModal = false;
    },
    calculateTax() {
      const TAX_RATE = 0.065;
      let price;
      if (this.salePrice) {
        price = this.salePrice;
      } else {
        price = this.fullPrice;
      }

      this.tax = price * TAX_RATE;
      this.tax = this.tax.toFixed(2);

      this.taxFree = price - this.tax;
      this.taxFree = this.taxFree.toFixed(2);
    },

    goHome() {
      window.location.href = "/";
    },
    fetchUser() {
      const url = envConfig.baseUrl + "/api/user";

      if (this.userToken) {
        axios
          .get(url, {
            headers: {
              Authorization: `Bearer ${this.userToken}`,
              "Content-Type": "application/json",
            },
          })
          .then((response) => {
            const data = response.data;
            this.userData = data.data;
            this.displayName = data.data.displayName;
            this.buyerId = data.data.id;

            if (!this.userData.allow_store) {
              window.location.href = "/";
            }
          })
          .catch((error) => {
            console.log(error);
          });
      }
    },
  },
};
</script>

<style scoped>
/* BUTTON*/
.primaryDisabled {
  border-radius: var(--radius-8px, 8px);
  background: var(--gray-800, #221e2a);
  border: none;
  color: rgba(156, 147, 179, 0.3);
}

.primary {
  color: white;
  background: linear-gradient(#ed063d, #950426) padding-box,
    linear-gradient(180deg, #fb6084, #b4042e, #950426) border-box !important;
  border-width: 1px;
  border-color: transparent;
  border-radius: 8px;
  border-bottom: none;
  box-shadow: 0px 1px 0.5px 0px rgba(14, 13, 18, 0.15);
}

.primary:hover {
  color: white;
  background: linear-gradient(#ed063d, #ed063d) padding-box,
    linear-gradient(180deg, #fb6084, #f11d4f, #ed063d) border-box;
  border-width: 1px;
  border-color: transparent;
  border-bottom: none;
  box-shadow: 0px 1px 0.5px 0px rgba(14, 13, 18, 0.15);
}

.primary:active {
  color: white;
  background: linear-gradient(#c20531, #c20531) padding-box,
    linear-gradient(180deg, #fb6084, #c20531, #c20531) border-box;
  border-width: 1px;
  border-color: transparent;
  border-radius: 8px;
  border-bottom: none;
  box-shadow: 0px 1px 0.5px 0px rgba(14, 13, 18, 0.15);
}

/* BUTTON END */

.disabled-content {
  pointer-events: none;
  filter: blur(2px);
}

#right {
  background: var(--gray-900, #16131b);
  box-shadow: 0px 15px 30px 0px #121212;
}

.divider {
  border-top: solid 1px #322c3f;
  border-bottom: solid 1px #322c3f;
}

form div {
  height: fit-content;
}

label {
  margin-bottom: 24px;
  color: #efedf2;
}

input,
select,
#expiryElement,
#cvcElement,
#cardElement {
  border-radius: 6px;
  border: 2px solid var(--gray-500, #564b6c);
  background: var(--gray-900, #16131b);
  box-shadow: 0px 2px 4px 0px rgba(0, 0, 0, 0.07);
  width: 100%;
}

select,
input::placeholder {
  color: #564b6c;
}

@media screen and (min-height: 1000px) {
  #right,
  #left {
    height: auto;
  }
}

@media screen and (min-width: 1024px) and (max-width: 1439.9px) {
  header {
    left: 12px;
  }

  #left {
    padding-left: 3%;
  }
}

/* Tablet View */
@media screen and (max-width: 1023.9px) {
  main,
  #right,
  #mobileItem,
  footer {
    background: var(--neutral-black-100, #121212);
    height: auto;
  }

  header {
    position: absolute;
    top: 0;
    right: 0;
    left: 0;

    background: black;
    padding: 24px 16px;
    display: flex;
    align-items: center;
    justify-content: center;
    margin: auto;
  }

  #left {
    display: none;
  }

  #right {
    margin: 106px auto 0 auto;
    padding: 0 16px;
  }

  form {
    padding-top: 32px 0 0 0;
    max-width: 704px;
  }

  footer {
    display: flex;
    justify-content: center;
    padding: 32px 0 24px 0;
    margin: auto;
  }

  .visibleDivider {
    display: none;
  }
}

@media screen and (max-width: 450px) {
  main,
  #right,
  #mobileItem,
  footer {
    background: #16131b;
  }

  #right {
    margin-top: 86px;
    padding-top: 10px;
  }

  form {
    padding: 0 0;
    margin: 0;
  }
}
</style>