const envConfig = {
  // Dev
  // baseUrl: 'http://localhost:8000',
  // websiteUrl: 'http://localhost:5173',

  // Staging:
  // baseUrl: "https://api-staging.ohbabygames.com",
  // websiteUrl: "http://www-staging.ohbabygames.com",
  // storeUrl: 'https://store-staging.ohbabygames.com',

  // Production:
  baseUrl: 'https://api.ohbabygames.com',
  websiteUrl: 'https://www.ohbabygames.com',
  storeUrl: 'https://store.ohbabygames.com',

  //stripePubKey: 'pk_test_51N4XYtKt605d2Be7ZNpvpbUzGuAdmieh3oHQ7yrGDX3IUxniTPIjb8epXSa84dBUsV7RgdCdqnxE8XEumb0qhiqD00H7Zn9j3h',
  stripePubKey: 'pk_live_51N4XYtKt605d2Be7flNVGJVSF1vao4lUp3s3IhTAhr6OcOYqG8XOs39LwFPaWOBQCGerTBJMVlvO7LIih84KjfaQ00LU3MeXlw',
};

export default envConfig;
